$primary: #1a1a1a;
$secondary: #fcde82;
$primaryHover: #6d9bc3;
$white: #fff;
$black: #000;
$text: #3e3e3e;
$active: #2da45d;
$danger: #fe840e;
$grayC: #ccc;

$statusBoxes: #8b572a;
$statusRunning: #2da45d;
$statusOn: #2da45d;
$statusStatus: #17a2b8;
$statusWarning: #fcde82;
$statusErrors: #fe840e;
$statusCritical: #ff2800;
$statusDisconnected: #949494;
$statusNoData: #eee;
$statusLWDO: #a938cc;
$statusStarts: #fcde82;
$statusWaiting: #2a3a4a;

:root {
    --status-boxes: #{$statusBoxes};
    --status-running: #{$statusRunning};
    --status-on: #{$statusOn};
    --status-status: #{$statusStatus};
    --status-warning: #{$statusWarning};
    --status-errors: #{$statusErrors};
    --status-critical: #{$statusCritical};
    --status-disconnected: #{$statusDisconnected};
    --status-no-data: #{$statusNoData};
    --status-lwdo: #{$statusLWDO};
    --status-starts: #{$statusStarts};
    --status-waiting: #{$statusWaiting};
}
