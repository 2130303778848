// TABLAS
table.table {
    font-size: 13px;
    margin-bottom: 15px;
    thead > tr > th {
        text-transform: uppercase;
        font-weight: bold;
        background-color: #f5f5f5;
        color: $text;
        border-top: 1px solid #d2d2d2 !important;
        border-bottom: 1px solid #d2d2d2;
        padding-right: 0;
        font-size: 12px;
        span {
            font-size: 13px;
        }
        &:last-child {
            span {
                border: none;
            }
        }
    }
    tbody tr {
        border-bottom: 1px solid #d9dfe0;
        td {
            i {
                padding-right: 5px;
            }
            .fa-circle {
                color: $danger;
            }
            .fa-dot-circle-o {
                color: $danger;
            }
            .fa-circle-o {
                color: $danger;
            }
        }
        &:last-child td {
            border-bottom: 1px solid #7f7f7f;
        }
    }
}

.ngx-datatable.bootstrap {
    &.loading:before {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 9999;
        content: 'Loading data...';
        text-align: center;
        line-height: 35;
        font-size: 14px;
        font-weight: bold;
        text-shadow:
            2px 0 0 $white,
            -2px 0 0 $white,
            0 2px 0 $white,
            0 -2px 0 $white,
            1px 1px $white,
            -1px -1px 0 $white,
            1px -1px 0 $white,
            -1px 1px 0 $white;
    }

    &.loading2:before {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 9999;
        content: 'Generating report... It will be available in My Archives shortly';
        white-space: pre;
        text-align: center;
        line-height: 35;
        font-size: 14px;
        font-weight: bold;
        text-shadow:
            2px 0 0 $white,
            -2px 0 0 $white,
            0 2px 0 $white,
            0 -2px 0 $white,
            1px 1px $white,
            -1px -1px 0 $white,
            1px -1px 0 $white,
            -1px 1px 0 $white;
    }

    box-shadow: none;
    font-size: 12px;

    .datatable-header {
        height: unset !important;
        border-top: 1px solid #d2d2d2;
        border-bottom: 1px solid #d2d2d2;
        box-shadow: 0 0 0 2px #f5f5f5;
        margin-bottom: 2px;

        .datatable-header-cell {
            text-transform: uppercase;
            font-weight: bold;
            background-color: #f5f5f5;
            color: $text;
            border-top: 1px solid $white;
            border-bottom: 1px solid $white;
            vertical-align: bottom;
            padding: 0.75rem;

            &.sortable:hover {
                cursor: pointer;
            }

            .datatable-header-cell-label {
                line-height: 24px;
            }

            .datatable-header-cell-template-wrap {
                overflow: hidden;
                text-overflow: ellipsis;
                &:after {
                    content: '';
                    position: absolute;
                    height: 22px;
                    width: 1px;
                    background-color: #d2d2d2;
                    top: 11px;
                    right: 0;
                }
            }
            &.empty .datatable-header-cell-template-wrap:after {
                background-color: transparent;
            }
        }
        .datatable-header-cell:last-of-type {
            .datatable-header-cell-template-wrap:after {
                display: none;
            }
        }
    }

    .datatable-body {
        .datatable-row-wrapper {
            position: relative;
            .datatable-group-header {
                border-top: 1px solid #d1d4d7;
            }
            &:first-of-type .datatable-group-header {
                border-top: none;
            }
        }
        .datatable-row-wrapper:first-of-type {
            .datatable-body-row {
                border-top: none;
            }
        }

        .datatable-row-wrapper:last-child {
            .datatable-body-row {
                border-bottom: 1px solid #7f7f7f;
            }
        }

        .datatable-body-row {
            vertical-align: top;
            border-top: 1px solid #d1d4d7;
            &.on-error {
                background-color: #ff9688;
                .fa.fa-sync-alt {
                    color: #000;
                }
            }
            &.negative-trend {
                background-color: #fff3f3;
                color: #000;
            }
            &.active {
                background-color: $secondary;
                color: $black;
            }

            &.resolved {
                background-color: rgba(245, 245, 245, 0.3);
                color: rgba(54, 54, 54, 0.5);
            }

            .datatable-body-cell {
                overflow: hidden;
                // padding: .75rem;
                text-align: left;
                vertical-align: top;

                span {
                    i.fa-circle {
                        font-size: 8px;
                        position: relative;
                        top: -2px;
                        padding-right: 2px;
                    }
                    &.highlight {
                        background-color: var(--secondary);
                        padding: 2px;
                    }
                }
            }
        }
    }

    .datatable-footer {
        margin-top: -1px;

        .page-count {
            line-height: 50px;
            height: 50px;
            padding: 0 1.2rem;
        }

        .datatable-pager {
            margin: 0 10px;
            vertical-align: top;

            ul {
                li {
                    margin: 10px 0px;
                    &.pages a {
                        padding: 0.5rem 0.9rem;
                    }
                    &.disabled i {
                        color: #909090;
                    }
                    a {
                        height: 38px;
                        color: $text;
                        padding: 0.345rem 0.75rem;
                        font-weight: bold;
                        margin: 0;
                        border-radius: 0;
                    }
                    &:not(.disabled) {
                        &.active a {
                            background-color: $primary;
                            color: $white;
                            &:hover {
                                color: $white;
                                cursor: default;
                            }
                        }
                        &:hover a {
                            background-color: var(--secondary);
                            color: $primary;
                        }
                    }
                }
            }
            a {
                height: 22px;
                min-width: 24px;
                line-height: 22px;
                padding: 0;
                border-radius: 3px;
                margin: 0 3px;
                text-align: center;
                vertical-align: top;
                text-decoration: none;
                vertical-align: bottom;
                color: #ededed;
            }
            .datatable-icon-left,
            .datatable-icon-skip,
            .datatable-icon-right,
            .datatable-icon-prev {
                font-size: 18px;
                line-height: 27px;
                padding: 0 3px;
            }
        }
    }

    .datatable-icon-up::before,
    .datatable-icon-down::before {
        display: table;
        font-family: 'Font Awesome 5 Free';
        font-weight: bold;
        font-style: normal;
        font-size: 12px;
        margin-top: -3px;
        padding-left: 0.35rem;
        color: $primary;
    }

    .datatable-icon-up::before {
        content: '\f0de';
    }

    .datatable-icon-down::before {
        content: '\f0dd';
    }

    .datatable-pager {
        i {
            font-family: 'Font Awesome 5 Free';
            font-style: normal;
        }
        .datatable-icon-prev::before {
            content: '\f100';
        }
        .datatable-icon-left::before {
            content: '\f104';
        }
        .datatable-icon-right::before {
            content: '\f105';
        }
        .datatable-icon-skip::before {
            content: '\f101';
        }
    }

    .datatable-header .datatable-header-cell.resizeable {
        .resize-handle {
            display: none;
        }
    }

    .empty-row {
        position: relative;
        padding: 20px;
        text-align: center;
        font-size: 14px;
        color: $text;
        border-bottom: 1px solid #7f7f7f;
    }

    .subtable {
        display: flex;
        margin-bottom: 0;

        tbody {
            width: 100%;
            background-color: #fafafa;

            tr {
                display: flex;

                &:last-child td {
                    border-bottom: none;
                }
            }
        }
    }
}
.datatable-footer-inner {
    overflow: hidden;
}
.datatable-tree-button:disabled {
    display: none;
}
.datatable-tree-button {
    background: transparent;
    border: none;
    padding-right: 12px;
    &:focus {
        outline: 0;
    }
}
.ngx-datatable.bootstrap.powerBi-table.ngx-datatable.scroll-vertical,
.ngx-datatable.bootstrap.powerBi-errors-table.ngx-datatable.scroll-vertical {
    height: 100%;
    .visible {
        height: 100%;
    }
    .datatable-body {
        height: 100% !important;
        max-height: calc(100vh - 258px) !important;
    }
}
.datatable-row-right .force-right {
    position: absolute;
    right: -17px;
    background-color: #fff;
    height: 38px !important;
}
.ngx-datatable.scroll-horz .datatable-body {
    overflow-y: hidden;
}

.ngx-datatable.bootstrap.alerts-table-shared.ngx-datatable.scroll-vertical {
    .datatable-body {
        height: auto !important; //calc(100vh - 196px) !important;
        max-height: calc(100vh - 196px) !important;
        .datatable-body-row .datatable-body-cell {
            padding: 0.21rem 0.75rem;
        }
    }
    .datatable-footer {
        height: 0 !important;
    }
}
.ngx-datatable.bootstrap.variables-table-shared {
    .datatable-body .datatable-body-row .datatable-body-cell {
        padding: 0.25rem 0.75rem;
    }
}
