@font-face {
  font-family: 'AuwaFont';
  src:  url('auwa.eot?x4m771');
  src:  url('auwa.eot?x4m771#iefix') format('embedded-opentype'),
    url('auwa.ttf?x4m771') format('truetype'),
    url('auwa.woff?x4m771') format('woff'),
    url('auwa.svg?x4m771#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'AuwaFont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-accept:before {
  content: "\e900";
}
.icon-alert:before {
  content: "\e901";
}
.icon-alert_active:before {
  content: "\e902";
}
.icon-alert_blocked:before {
  content: "\e903";
}
.icon-alert_confirmation:before {
  content: "\e904";
}
.icon-alert_working:before {
  content: "\e905";
}
.icon-calendar:before {
  content: "\e906";
}
.icon-close:before {
  content: "\e907";
}
.icon-close_session:before {
  content: "\e908";
}
.icon-communication_off:before {
  content: "\e909";
}
.icon-communication_on:before {
  content: "\e90a";
}
.icon-dashboard:before {
  content: "\e90b";
}
.icon-database:before {
  content: "\e90c";
}
.icon-download:before {
  content: "\e90d";
}
.icon-filter:before {
  content: "\e90e";
}
.icon-freedraw:before {
  content: "\e90f";
}
.icon-fullscreen:before {
  content: "\e910";
}
.icon-graph_down:before {
  content: "\e911";
}
.icon-graph_up:before {
  content: "\e912";
}
.icon-help:before {
  content: "\e913";
}
.icon-ico_abridora:before {
  content: "\e914";
}
.icon-ico_bascula:before {
  content: "\e915";
}
.icon-ico_cerradora:before {
  content: "\e916";
}
.icon-ico_etiquetadora:before {
  content: "\e917";
}
.icon-ico_flejadora:before {
  content: "\e918";
}
.icon-ico_horno:before {
  content: "\e919";
}
.icon-ico_sorter:before {
  content: "\e91a";
}
.icon-ico_volumetrica:before {
  content: "\e91b";
}
.icon-instalations:before {
  content: "\e91c";
}
.icon-maintenance:before {
  content: "\e91d";
}
.icon-menu:before {
  content: "\e91e";
}
.icon-move:before {
  content: "\e91f";
}
.icon-new_order:before {
  content: "\e920";
}
.icon-resize:before {
  content: "\e921";
}
.icon-search:before {
  content: "\e922";
}
.icon-select:before {
  content: "\e923";
}
.icon-server:before {
  content: "\e924";
}
.icon-square:before {
  content: "\e925";
}
.icon-velocimeter:before {
  content: "\e926";
}
.icon-view_all:before {
  content: "\e927";
}
.icon-viewbox:before {
  content: "\e928";
}
