@import 'colors.scss';
@import '../../node_modules/@ng-select/ng-select/themes/default.theme.css';
@import '../../node_modules/@ays/commons/assets/fonts/auwa-font.css';
@import '../../node_modules/@ays/commons/assets/fonts/inter/Inter.css';
//@import '../../node_modules/@ays/commons/assets/fonts/abcmonumentgrotesk/ABCMonumentGrotesk.css';
@import '../../node_modules/@ays/commons/lib/styles';
@import 'dark.scss';
@import 'datatable.scss';

html.fullscreen {
    overflow: auto;
}
#outline feFlood {
    flood-color: $white;
}
.navbar-light .navbar-brand img {
    width: 184px !important;
}
.search-nav {
    padding-bottom: 10px;
    border-bottom: solid 1px #eee;
    h2 {
        font-size: 24px;
        color: $text;
        margin: 10px 0 0 0;
    }
    input {
        border-radius: 2px;
    }
}

.datatable-row-right,
.datatable-row-left {
    background-color: #fff;
}

.active .datatable-row-right,
.active .datatable-row-left {
    background-color: #fcde82;
}

.powerBi-table .datatable-header-inner {
    background-color: #f5f5f5;
}

.powerBi-table .datatable-body {
    height: auto !important;
    max-height: 696px !important;
}

.powerBi-table.zones .line.datatable-body-row .datatable-row-group:first-of-type .datatable-body-cell:first-of-type .datatable-body-cell-label {
    margin-left: 29px !important;
}
.powerBi-table.lines .line.datatable-body-row .datatable-row-group:first-of-type .datatable-body-cell:first-of-type .datatable-body-cell-label {
    margin-left: 10px !important;
}
.powerBi-errors-table.zones {
    .line.datatable-body-row .datatable-row-group:first-of-type .datatable-body-cell:first-of-type .datatable-body-cell-label {
        margin-left: 15px !important;
    }
    .equipment.datatable-body-row .datatable-row-group:first-of-type .datatable-body-cell:first-of-type .datatable-body-cell-label {
        margin-left: 30px !important;
    }
    .error.datatable-body-row .datatable-row-group:first-of-type .datatable-body-cell:first-of-type .datatable-body-cell-label {
        margin-left: 57px !important;
        .datatable-tree-button {
            display: none;
        }
    }
}
.powerBi-errors-table.lines {
    .equipment.datatable-body-row .datatable-row-group:first-of-type .datatable-body-cell:first-of-type .datatable-body-cell-label {
        margin-left: 15px !important;
    }
    .error.datatable-body-row .datatable-row-group:first-of-type .datatable-body-cell:first-of-type .datatable-body-cell-label {
        margin-left: 43px !important;
        .datatable-tree-button {
            display: none;
        }
    }
}
.powerBi-errors-table.equipments {
    .error.datatable-body-row .datatable-row-group:first-of-type .datatable-body-cell:first-of-type .datatable-body-cell-label {
        margin-left: 29px !important;
        .datatable-tree-button {
            display: none;
        }
    }
}
.powerBi-error-trend-table .datatable-body-row.isDeepestElement .datatable-row-center .datatable-body-cell:first-of-type .datatable-body-cell-label > .datatable-tree-button {
    display: none;
}

.powerBi-error-trend-table .datatable-body-row.isDeepestElement .datatable-row-center .datatable-body-cell:first-of-type .datatable-body-cell-label > .datatable-tree-button {
    display: none;
}

.page-item.active .page-link {
    background-color: $primary;
    border-color: $primary;
}

ul.nav.nav-tabs {
    margin-bottom: 25px;
}

table.dataTable span.highlight {
    background-color: $primary;
    color: $white;
}

div.dataTables_wrapper {
    padding: 0;

    div.dataTables_info {
        font-size: 13px;
        font-weight: bold;
        padding-top: 8px !important;
        display: inline-block;
    }

    div.dataTables_length {
        font-size: 13px;
        display: inline-block;

        label {
            font-weight: bold;
        }

        select {
            border: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: auto !important;
            color: $primary;
            font-weight: bold;
            padding: 0;
            margin-right: 3px;
        }
    }

    .buttons-excel {
        float: right;
        border: none;
        background: none;
        color: $primary;
        padding: 8px 0 0 0;
        outline: none;

        i {
            font-size: 24px;
            color: #95a6a7;
            padding-right: 8px;
        }

        &:focus {
            outline: none;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.button-excel {
    font-size: 13px;
    font-weight: bold;
    float: right;
    border: none;
    background: none;
    color: $primary;
    outline: none;

    i {
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #95a6a7;
        padding-right: 8px;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        cursor: pointer;
    }
}

.button-refresh {
    span {
        border-right: 1px solid #d2d2d2;
        padding-right: 15px;
        font-weight: bold;
    }

    font-size: 13px;
    float: right;
    border: none;
    background: none;
    color: $primary;
    outline: none;

    &.disabled {
        color: #d2d2d2;
        // i { animation:rotate 1s linear infinite; }
    }

    i {
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #95a6a7;
        padding-right: 8px;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        cursor: pointer;
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
.table-critical:after {
    content: '';
    position: absolute;
    width: 6px;
    height: calc(100% - 1px);
    left: 0;
    top: 0;
    background-color: #dc3545;
}
.table-disconnected-zone {
    font-style: italic;
    text-decoration: line-through;
}
table.table-hide tbody {
    position: relative;
    height: 200px;
    width: 100%;

    &::after {
        position: absolute;
        font-family: 'Font Awesome 5 Free';
        font-size: 32px;
        content: '\f110';
        left: 50%;
        top: 50%;
        overflow: hidden;
        margin-top: -24px;
        margin-left: -20px;
        -webkit-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
    }

    & > tr {
        border: none;

        & > td {
            border: none;
            padding: 0;
            font-size: 0;
            opacity: 0;
        }

        &:last-child > td {
            border-bottom: none;
        }
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.accordion .card {
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
    border-top-left-radius: 3px !important;
}

.accordion .card-header {
    padding: 0 0 0 50px;
    background-color: $white;
}

.accordion .card-header {
    border-top-left-radius: 3px !important;

    a {
        color: $text;

        &:after {
            position: absolute;
            font-size: 24px;
            font-family: 'Font Awesome 5 Free';
            content: '\f0d7';
            left: 0;
            top: 0;
            color: $primary;
            background-color: $white;
            padding: 7px 19px 7px 17.5px;
            border-top-left-radius: 3px;
            font-weight: bold;
        }
    }
}

.accordion .card-header a[aria-expanded='true']:after {
    content: '\f0d8';
    color: $white;
    background-color: $primary;
}

.card-body > .row:first-child .area-title {
    margin: 0;
}

.nav.nav-tabs.secondary-tab {
    width: auto;
    display: inline-flex;
    border-radius: 2px;
    border-bottom: none;

    .nav-link {
        margin: 4px;
        font-weight: normal;
        font-size: 12px;
        color: $text;
        border-radius: 2px;
        border-color: #d2d2d2;
        background-color: #fff;
        span {
            font-size: 16px;
            line-height: 20px;
            font-weight: bold;
            display: block;
        }

        &.active {
            border-color: #7f8d8e;
            background-color: #fafafa;
            box-shadow: 2px 2px 0px rgba(210, 210, 210, 0.5);

            &:before {
                font-family: 'Font Awesome 5 Free';
                font-size: 16px;
                content: '\f0d7';
                color: #7f8d8e;
                position: absolute;
                bottom: -10px;
                left: 50%;
                margin-left: -5px;
                font-weight: bold;
            }
        }
    }
}

.col-lg-6.col-12.svg-wrapper.home {
    padding: 0;
    .map-wrapper {
        border: none;
        margin-bottom: 0;
    }
    h4 {
        padding: 15px 15px 0;
        line-height: 17px;

        i {
            margin-top: -1px !important;
        }
    }
}
.col-lg-3.col-md-6.col-12.border .map-wrapper {
    border: none !important;
}
.col-md-6.col-12.border,
.col-lg-6.col-12.lite-wrapper,
.col-lg-6.col-12.svg-wrapper {
    .map-wrapper {
        border: 1px solid #d2d2d2;
        font-size: 0;
    }
    h4 {
        padding: 0 20px;
        margin-bottom: 0;
        font-size: 18px;
        color: $text;
        padding-top: 16px;
        a {
            float: right;
            margin-top: 4px;
            font-size: 13px;
        }
        i {
            padding: 0 8px;
        }
    }

    svg {
        width: 100%;
        padding: 15px;

        polyline.shape {
            fill: none;
            stroke: #555;
            stroke-width: 0.05;
        }

        .freeBacksGroup {
            path {
                fill: #6e6e6e;
                stroke-width: 0.05;

                &:hover {
                    cursor: default;
                    fill: #6e6e6e;
                }
            }
        }
        .freeFrontsGroup {
            path {
                fill: #b4b4b4;
                stroke-width: 0.05;
                &:hover {
                    cursor: default;
                    fill: #b4b4b4;
                }
            }

            polyline.shape {
                stroke-width: 2;
            }
        }
        .freeFrontsGroup {
            path {
                fill: #b4b4b4;
                stroke-width: 0.05;
                &:hover {
                    cursor: default;
                    fill: #b4b4b4;
                }
            }
            polyline.shape {
                stroke-width: 2;
            }
        }

        text {
            fill: black;
            font-weight: bold;
        }
        g.selected {
            outline: dotted 7px #3232ce;
            outline-offset: 5px;
            opacity: 1;
        }
        path {
            fill: #dedede;
            stroke: white;
            &.ground {
                stroke-width: 0.05;
                stroke: #555;
                fill: url(#texture) !important;
            }
            &:hover {
                fill: #cbcccc;
                cursor: pointer;
            }
            &.disconnected {
                fill: url(#pattern-unknown-disconnected) !important;
            }
            &.box {
                fill: url(#pattern-unknown-box);
            }
            &.warning {
                fill: url(#pattern-unknown-warning);
            }
            &.warning.box {
                fill: url(#pattern-unknown-warning-box);
            }
            &.run {
                fill: $active;
                &:hover {
                    fill: darken($active, 10%);
                }
                &.box {
                    fill: url(#pattern-run-box);
                }
                &.warning {
                    fill: url(#pattern-run-warning);
                }
                &.warning.box {
                    fill: url(#pattern-run-warning-box);
                }
            }
            &.lwdo {
                fill: var(--status-lwdo);
                &:hover {
                    fill: darken($statusLWDO, 10%);
                }
                &.box {
                    fill: url(#pattern-without-permission-box);
                }
                &.warning {
                    fill: url(#pattern-without-permission-warning);
                }
                &.warning.box {
                    fill: url(#pattern-without-permission-warning-box);
                }
            }
            &.alert,
            &.alert_low,
            &.maintenance_pending,
            &.maintenance_blocked,
            &.maintenance_processing {
                fill: $danger;
                &:hover {
                    fill: darken($danger, 10%);
                }
                &.box {
                    fill: url(#pattern-alert-box);
                }
                &.warning {
                    fill: url(#pattern-alert-warning);
                }
                &.warning.box {
                    fill: url(#pattern-alert-warning-box);
                }
            }
            &.critical {
                fill: $statusCritical;
                &:hover {
                    fill: darken($statusCritical, 10%);
                }
                &.box {
                    fill: url(#pattern-critical-box);
                }
                &.warning {
                    fill: url(#pattern-critical-warning);
                }
                &.warning.box {
                    fill: url(#pattern-critical-warning-box);
                }
            }
            &.marker {
                fill: #2a3a4a;
                stroke: none;
            }
            &.markedLine {
                stroke: $secondary !important;
                stroke-width: 0.5;
                stroke-dasharray: 100;
                animation: dash 7s linear infinite;
            }
        }
        .variablesGroup {
            path {
                &:hover {
                    cursor: default;
                }
            }
            g.block {
                path.highlightPath {
                    fill: #76caf5;
                }
            }
        }
    }
}

@media (max-width: 991.98px) {
    .col-lg-6.col-12.svg-wrapper {
        .map-wrapper {
            border: none;
        }

        h4 {
            padding: 20px 0 0 5px;
        }
    }
}
.linesOverMap-wrapper {
    background-color: #f5f5f5;
}
.linesOverMap-title {
    padding-left: 5px;
    font-size: 13px;
}

.btn-zones {
    padding: 5px;
    .btn {
        width: 100%;
    }
}

.btn-zones > .btn {
    font-size: 13px;
    border-radius: 2px;
    background-color: #d2d2d2;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
        cursor: pointer;
    }
    &.box {
        background: linear-gradient(to right, #d2d2d2 2%, var(--status-boxes) 2%, var(--status-boxes) 5%, #d2d2d2 5%);
    }
    &.disconnected {
        background: linear-gradient(to right, #d2d2d2 2%, #000 2%, #000 5%, #d2d2d2 5%);
    }
    &.warning {
        background: linear-gradient(to right, #d2d2d2 2%, var(--status-warning) 2%, var(--status-warning) 5%, #d2d2d2 5%);
    }
    &.box.warning {
        background: linear-gradient(
            to right,
            #d2d2d2 2%,
            var(--status-boxes) 2%,
            var(--status-boxes) 5%,
            var(--status-warning) 5%,
            var(--status-warning) 8%,
            #d2d2d2 8%
        );
    }
    &.box.disconnected {
        background: linear-gradient(to right, #d2d2d2 2%, var(--status-boxes) 2%, var(--status-boxes) 5%, #000 5%, #000 8%, #d2d2d2 8%);
    }
    &.warning.disconnected {
        background: linear-gradient(to right, #d2d2d2 2%, #000 2%, #000 5%, var(--status-warning) 5%, var(--status-warning) 8%, #d2d2d2 8%);
    }
    &.box.warning.disconnected {
        background: linear-gradient(
            to right,
            #d2d2d2 2%,
            var(--status-boxes) 2%,
            var(--status-boxes) 5%,
            #000 5%,
            #000 8%,
            var(--status-warning) 8%,
            var(--status-warning) 11%,
            #d2d2d2 11%
        );
    }
    i {
        background-color: #2a3a4a;
        border-radius: 50%;
        margin-right: 3px;
        width: 18px;
        height: 18px;
        font-size: 0.8rem;
        display: inline-block;
        line-height: 18px;
    }
    &.run {
        background-color: $active;
        color: $white;
        &:hover {
            background-color: var(--status-running);
        }
        &.box {
            background: linear-gradient(to right, var(--status-running) 2%, var(--status-boxes) 2%, var(--status-boxes) 5%, var(--status-running) 5%);
        }
        &.disconnected {
            background: linear-gradient(to right, var(--status-running) 2%, #000 2%, #000 5%, var(--status-running) 5%);
        }
        &.warning {
            background: linear-gradient(to right, var(--status-running) 2%, var(--status-warning) 2%, var(--status-warning) 5%, var(--status-running) 5%);
        }
        &.box.warning {
            background: linear-gradient(
                to right,
                var(--status-running) 2%,
                var(--status-boxes) 2%,
                var(--status-boxes) 5%,
                var(--status-warning) 5%,
                var(--status-warning) 8%,
                var(--status-running) 8%
            );
        }
        &.box.disconnected {
            background: linear-gradient(
                to right,
                var(--status-running) 2%,
                var(--status-boxes) 2%,
                var(--status-boxes) 5%,
                #000 5%,
                #000 8%,
                var(--status-running) 8%
            );
        }
        &.warning.disconnected {
            background: linear-gradient(
                to right,
                var(--status-running) 2%,
                #000 2%,
                #000 5%,
                var(--status-warning) 5%,
                var(--status-warning) 8%,
                var(--status-running) 8%
            );
        }
        &.box.warning.disconnected {
            background: linear-gradient(
                to right,
                var(--status-running) 2%,
                var(--status-boxes) 2%,
                var(--status-boxes) 5%,
                #000 5%,
                #000 8%,
                var(--status-warning) 8%,
                var(--status-warning) 11%,
                var(--status-running) 11%
            );
        }
    }
    &.lwdo {
        background-color: var(--status-lwdo);
        color: $white;
        &:hover {
            background-color: darken($statusLWDO, 10%);
        }
        &.box {
            background: linear-gradient(to right, var(--status-lwdo) 2%, var(--status-boxes) 2%, var(--status-boxes) 5%, var(--status-lwdo) 5%);
        }
        &.disconnected {
            background: linear-gradient(to right, var(--status-lwdo) 2%, #000 2%, #000 5%, var(--status-lwdo) 5%);
        }
        &.warning {
            background: linear-gradient(to right, var(--status-lwdo) 2%, var(--status-warning) 2%, var(--status-warning) 5%, var(--status-lwdo) 5%);
        }
        &.box.warning {
            background: linear-gradient(
                to right,
                var(--status-lwdo) 2%,
                var(--status-boxes) 2%,
                var(--status-boxes) 5%,
                var(--status-warning) 5%,
                var(--status-warning) 8%,
                var(--status-lwdo) 8%
            );
        }
        &.box.disconnected {
            background: linear-gradient(
                to right,
                var(--status-lwdo) 2%,
                var(--status-boxes) 2%,
                var(--status-boxes) 5%,
                #000 5%,
                #000 8%,
                var(--status-lwdo) 8%
            );
        }
        &.warning.disconnected {
            background: linear-gradient(
                to right,
                var(--status-lwdo) 2%,
                #000 2%,
                #000 5%,
                var(--status-warning) 5%,
                var(--status-warning) 8%,
                var(--status-lwdo) 8%
            );
        }
        &.box.warning.disconnected {
            background: linear-gradient(
                to right,
                var(--status-lwdo) 2%,
                var(--status-boxes) 2%,
                var(--status-boxes) 5%,
                #000 5%,
                #000 8%,
                var(--status-warning) 8%,
                var(--status-warning) 11%,
                var(--status-lwdo) 11%
            );
        }
    }

    &.maintenance_pending,
    &.maintenance_blocked,
    &.maintenance_processing,
    &.maintenance_finished,
    &.alert,
    &.alert_low {
        background-color: $danger;
        color: $white;
        padding: 0.375rem 0.75rem;
        margin-bottom: 0;
        &:hover {
            background-color: darken($danger, 10%);
        }
        &.box {
            background: linear-gradient(to right, $danger 2%, var(--status-boxes) 2%, var(--status-boxes) 5%, $danger 5%);
        }
        &.disconnected {
            background: linear-gradient(to right, $danger 2%, #000 2%, #000 5%, $danger 5%);
        }
        &.warning {
            background: linear-gradient(to right, $danger 2%, var(--status-warning) 2%, var(--status-warning) 5%, $danger 5%);
        }
        &.box.warning {
            background: linear-gradient(
                to right,
                $danger 2%,
                var(--status-boxes) 2%,
                var(--status-boxes) 5%,
                var(--status-warning) 5%,
                var(--status-warning) 8%,
                $danger 8%
            );
        }
        &.box.disconnected {
            background: linear-gradient(to right, $danger 2%, var(--status-boxes) 2%, var(--status-boxes) 5%, #000 5%, #000 8%, $danger 8%);
        }
        &.warning.disconnected {
            background: linear-gradient(to right, $danger 2%, #000 2%, #000 5%, var(--status-warning) 5%, var(--status-warning) 8%, $danger 8%);
        }
        &.box.warning.disconnected {
            background: linear-gradient(
                to right,
                $danger 2%,
                var(--status-boxes) 2%,
                var(--status-boxes) 5%,
                #000 5%,
                #000 8%,
                var(--status-warning) 8%,
                var(--status-warning) 11%,
                $danger 11%
            );
        }
    }
    &.critical {
        background-color: var(--status-critical);
        &:hover {
            background-color: darken($statusCritical, 10%);
        }
        &.box {
            background: linear-gradient(to right, var(--status-critical) 2%, var(--status-boxes) 2%, var(--status-boxes) 5%, var(--status-critical) 5%);
        }
        &.disconnected {
            background: linear-gradient(to right, var(--status-critical) 2%, #000 2%, #000 5%, var(--status-critical) 5%);
        }
        &.warning {
            background: linear-gradient(to right, var(--status-critical) 2%, var(--status-warning) 2%, var(--status-warning) 5%, var(--status-critical) 5%);
        }
        &.box.warning {
            background: linear-gradient(
                to right,
                var(--status-critical) 2%,
                var(--status-boxes) 2%,
                var(--status-boxes) 5%,
                var(--status-warning) 5%,
                var(--status-warning) 8%,
                var(--status-critical) 8%
            );
        }
        &.box.disconnected {
            background: linear-gradient(
                to right,
                var(--status-critical) 2%,
                var(--status-boxes) 2%,
                var(--status-boxes) 5%,
                #000 5%,
                #000 8%,
                var(--status-critical) 8%
            );
        }
        &.warning.disconnected {
            background: linear-gradient(
                to right,
                var(--status-critical) 2%,
                #000 2%,
                #000 5%,
                var(--status-warning) 5%,
                var(--status-warning) 8%,
                var(--status-critical) 8%
            );
        }
        &.box.warning.disconnected {
            background: linear-gradient(
                to right,
                var(--status-critical) 2%,
                var(--status-boxes) 2%,
                var(--status-boxes) 5%,
                #000 5%,
                #000 8%,
                var(--status-warning) 8%,
                var(--status-warning) 11%,
                var(--status-critical) 11%
            );
        }
    }
    &.disconect {
        background-image: url('data:image/svg+xml;base64, PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc1JyBoZWlnaHQ9JzUnPg0KICAgIDxyZWN0IHdpZHRoPSc1JyBoZWlnaHQ9JzUnIGZpbGw9JyNjN2M3YzcnIC8+DQogICAgPHBhdGggZD0nTTAgNUw1IDBaTTYgNEw0IDZaTS0xIDFMMSAtMVonIHN0cm9rZT0nI2Y1ZjVmNScgc3Ryb2tlLXdpZHRoPScxJyAvPg0KPC9zdmc+');
        background-repeat: repeat;
    }
}

.btn-group-auwa {
    height: 40px;
    width: 100%;
    background-color: $primary;
    padding: 3px;
    border-radius: 2px;

    & > .btn-auwa {
        height: 34px;
        width: 100%;
        padding: 0.375rem 0.75rem;

        &.active {
            color: $primary;
            background-color: $white;
            z-index: 0;
            &:hover {
                color: var(--secondary-text);
                background-color: var(--secondary);
            }
        }

        &:hover {
            background-color: $primary;
            color: var(--secondary);
        }
    }
}

.btn-auwa {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 9px 15px;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 2px;
    color: $white;
    background-color: $primary;

    &:hover {
        color: var(--secondary-text);
        background-color: var(--secondary);
    }

    &:focus {
        box-shadow: none;
        // background-color: $primaryHover;
    }

    i {
        padding-right: 10px;
        font-size: 20px;
    }
}

.popover {
    max-width: 365px;
    width: 365px;
    border: 2px solid #7f8d8e;
    border-radius: 1px;

    p {
        margin: 0;
        font-size: 13px;
    }

    .arrow:after {
        border-top-color: #7f8d8e !important;
    }
}

.auwa-color-picker {
    align-items: center;
    justify-content: center;
    font-weight: bold;
    padding: 0.375rem 0.75rem;
    font-size: 8px;
    text-transform: uppercase;
    border: 1px solid;
    border-radius: 2px;
    color: white;
    background-color: $primary;

    &:hover {
        color: $black;
        background-color: var(--secondary);
    }

    &:focus {
        box-shadow: none;
    }
}
.btn-primary {
    background-color: $primary;
    border-color: $primary;
}

.ps__thumb-y {
    background-color: #475d6b;
}

.table.fixedHeader-floating {
    top: 80px !important;
}

.force-one-line {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.preloaderPopover svg {
    position: absolute;
    width: 60px;
    left: 50%;
    margin-left: -35px;
    top: 65px;
}

.empty-table-icon {
    position: absolute;
    color: #bbc4c7;
    font-size: 42px;
    top: 50%;
    left: 50%;
    margin-left: -22px;
    margin-top: -67px;
}

.tooltip {
    &.show {
        opacity: 1;
    }

    .tooltip-inner {
        background-color: $white;
        color: $text;
        border: 2px solid #7f8d8e;
        border-radius: 1px;
    }

    .arrow::before {
        border-top-color: #7f8d8e !important;
    }
}

.performance-single {
    .datatable-body-cell-label {
        height: 19px;

        .performance-chart {
            font-size: 0;
            display: inline-block;
            width: 100%;
        }
    }
}

.performance-group {
    .datatable-body-cell-label {
        height: 19px;

        .performance-values {
            display: inline-block;
            padding-left: 10px;
            width: 150px;
            position: relative;
            top: -5px;
        }

        .performance-chart {
            font-size: 0;
            display: inline-block;
            width: calc(100% - 150px);
            border-radius: 2px;
            overflow: hidden;
            max-height: 20px;

            span {
                max-width: 100%;
            }
        }
    }
}

.performance-chart-wrapper {
    width: 50%;
    display: inline-block;

    &:first-of-type {
        padding-right: 5px;
    }

    &:last-of-type {
        padding-left: 5px;
    }
}

.performance-chart {
    font-size: 0;
    display: inline-block;
    width: 100%;
}

.singleBar-chart {
    font-size: 0;
    overflow: hidden;
    white-space: nowrap;
}

html.no-scroll {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: visible;
}
.full-screen-chart {
    position: fixed;
    width: 100%;
    height: calc(100% - 66px) !important;
    left: 0;
    top: 66px;
    background-color: #fff;
    padding: 32px;
    z-index: 99;
}

.mapael .zoomButton {
    background-color: #fff;
    border: 1px solid #ccc;
    color: #000;
    width: 17px;
    height: 17px;
    line-height: 14px;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    position: absolute;
    top: 0;
    font-weight: bold;
    left: 10px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.mapael .zoomReset {
    left: 10px;
}

.mapael .zoomIn {
    left: 32px;
}

.mapael .zoomOut {
    left: 54px;
}

.iconType {
    position: relative;
    display: block;

    i {
        position: absolute;
        font-size: 16px;
        top: -7px;
        left: 0;
    }

    i.fa-circle {
        left: 4px;
    }

    i.fa-cog {
        left: 0;
    }
}

.buttons-wrapper {
    padding: 1rem;
}

@media (max-width: 991.98px) {
    .buttons-wrapper {
        padding: 1rem 0;
    }
}

.filter-button {
    display: table;
    padding: 8px 25px;
    border-right: 1px solid #d2d2d2;

    i {
        color: $primary;
        font-size: 20px;
        display: table-cell;
        vertical-align: middle;
    }

    span {
        color: $primary;
        font-size: 13px;
        font-weight: bold;
        display: table-cell;
        vertical-align: middle;
        padding-left: 7px;
    }

    &:hover {
        cursor: pointer;
    }
}

@media (max-width: 991.98px) {
    .filter-button {
        border-left: 1px solid #d2d2d2;
        border-right: none;
        margin-bottom: 10px;
    }
}

@keyframes dash {
    to {
        stroke-dashoffset: 1000;
    }
}

.toast {
    background-color: $white;
}

.toast-header {
    color: $text;
    background-color: $secondary;
}

.autocomplete .highlight {
    background-color: var(--secondary);
    padding: 2px;
}

@-moz-keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.toast {
    background-color: $white;
}

.toast-header {
    color: $text;
    background-color: $secondary;
}

.toast-header-error {
    color: $white;
    background-color: #dc3545;

    .text-muted {
        color: #dedede !important;
    }

    .close {
        color: $white;
    }
}

.toast-wrapper {
    position: fixed;
    width: 100%;
    top: 14px;
    right: 14px;
    z-index: 15;

    .toast {
        position: relative;
        float: right;
    }
}

.tooltip-equipment-type {
    position: absolute;
    background-color: #7f8d8e;
    color: $white;
    top: -10px;
    left: 0;
    padding: 0 10px;
    font-weight: bold;
    text-transform: uppercase;
}

@-webkit-keyframes spinner

/* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.spinner {
    -webkit-animation: spinner 1.6s linear infinite;
    -moz-animation: spinner 1.6s linear infinite;
    -ms-animation: spinner 1.6s linear infinite;
    -o-animation: spinner 1.6s linear infinite;
    animation: spinner 1.6s linear infinite;
}
.ng-select.ng-select-opened .ng-select-container {
    z-index: 0 !important;
}
html.fullscreen .map-wrapper.expand {
    top: 0 !important;
    height: 100% !important;
}
.badge-wrapper {
    margin-top: 1px;
    .badge {
        font-size: 13px;
    }
}
.variable-detail .badge-wrapper .badge .bg-white {
    position: absolute;
    top: 5px;
    right: 4px;
}

.ngx-datatable.bootstrap .datatable-header .fas.fa-filter {
    position: absolute;
    padding: 5px 5px;
    border-radius: 50%;
    top: 9px;
    left: 4px;
    &:hover {
        background-color: var(--secondary);
    }
}

// TABLE HEADER FILTERS
.datatables-header-filter {
    position: absolute;
    top: -100px;
    left: -100px;
    width: 330px;
    height: 60px;
    background-color: white;
    z-index: 15;
    border: 2px solid #7f8d8e;
    border-radius: 1px;
    &:focus {
        outline: 0;
    }
    .arrow::after,
    .arrow::before {
        position: absolute;
        display: block;
        content: '';
        border-color: transparent;
        border-top-color: transparent;
        border-style: solid;
        left: 150px;
    }
    .arrow::after {
        bottom: -8px;
        border-width: 0.5rem 0.5rem 0;
        border-top-color: #fff;
    }
    .arrow::before {
        bottom: -9px;
        border-width: 0.5rem 0.5rem 0;
        border-top-color: rgba(0, 0, 0, 0.25);
    }
    &.first {
        .arrow::after,
        .arrow::before {
            left: 7px;
        }
    }
}
.filter-applyed {
    position: absolute;
    bottom: 0px;
    font-weight: 100;
    left: 28px;
    overflow: hidden;
    font-size: 10px;
    font-style: italic;
    padding-right: 2px;
    overflow: hidden;
    width: calc(100% - 30px);
    color: #198754;
    text-align: left;
}
.offcanvas-header {
    justify-content: space-between;
}
.contextMenuHtml {
    display: none;
    position: absolute;
    padding: 8px;
    border-radius: 10px;
    background-color: #fcfcfc;
    border: 1px solid #ccc;
    box-shadow: 15px 15px grey;

    .dropdown-item {
        margin-top: 3px;
        font-size: 15px;
    }
    .dropdown-item:hover {
        font-weight: bold;
    }
}

.read-only-mode .form-check-label {
    opacity: 1 !important;
    &::before {
        cursor: not-allowed;
        opacity: 0.5 !important;
    }
}

.tree-view div.form-check {
    padding-left: 0px;
}
