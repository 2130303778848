@font-face {
  font-family: 'Inter';
  src:  url('Inter-Regular.woff2') format('woff2'),
  url('Inter-Regular.woff') format('woff'),
  url('Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src:  url('Inter-Bold.woff2') format('woff2'),
  url('Inter-Bold.woff') format('woff'),
  url('Inter-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
