@import 'colors.scss';
html.dark {
    #outline feFlood {
        flood-color: #282828;
    }
    input[type='range']::-webkit-slider-runnable-track {
        background: #fff;
    }
    @-moz-document url-prefix() {
        .rangeslider::before {
            background: #fff;
        }
    }
    input[type='range']:hover::-moz-range-thumb,
    input[type='range']:hover::-webkit-slider-thumb {
        border-color: #fff;
    }
    .zone-rectangle-marker {
        fill: #363636;
        stroke: $white;
    }
    .filters-wrapper,
    .ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
        background-color: $text;
    }
    .search-nav h2 {
        color: $white;
    }
    .navbar-toggler {
        background-color: $white;
    }
    .filter-button > * {
        color: $white;
    }
    .ngx-datatable.bootstrap .datatable-icon-up::before,
    .ngx-datatable.bootstrap .datatable-icon-down::before {
        color: $secondary;
    }
    .ngx-datatable.bootstrap .subtable tbody {
        background-color: $primary;
        color: $white;
    }
    .datatable-body-row.active + .datatable-row-detail .subtable tr.active {
        color: $primary;
        background-color: $secondary;
    }
    .datatable-body-row.active + .datatable-row-detail .subtable tr.active.text-warning,
    .datatable-body-row.active + .datatable-row-detail .subtable tr.active.text-danger {
        color: $primary !important;
    }
    .ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
        color: $white;
    }
    .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li a {
        color: $white;
    }
    .ngx-datatable.bootstrap .datatable-header {
        border-color: $white;
        box-shadow: 0 0 0 2px $text;
        .datatable-header-cell {
            border-color: #202020;
        }
    }
    .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
        background-color: $secondary;
        color: $primary;
    }
    .datatable-body-cell-label a.fa {
        color: $white;
        &:hover {
            color: $secondary;
        }
    }
    .loading-overlay {
        background-color: rgba(0, 0, 0, 0.8);
        .loading-wrapper {
            text-shadow: 2px 2px $black !important;
            .loader-wrapper img {
                background-color: transparent !important;
            }
        }
    }
    .modal-content {
        background-color: #282828;
    }
    .modal-lateral-wrapper {
        background-color: rgba(0, 0, 0, 0.8);
        .modal-lateral {
            border-color: $black;
            background-color: #282828 !important;
            .modal-btns {
                background-color: #282828;
            }
            .header::before {
                border-color: $black !important;
            }
        }
    }
    .popover {
        border-color: #282828;
        background-color: #282828;
    }
    .popover-header {
        color: $secondary;
        background-color: $primary;
    }
    .popover-body {
        color: $white;
        background-color: $text;
    }
    .bs-popover-auto[x-placement^='right'] .arrow::after,
    .bs-popover-right .arrow::after {
        border-right-color: $text;
    }
    .warehouse-nav {
        border-color: $white;
        background-color: $text;
        box-shadow: 0 0 0 2px $text;
        .col-12 {
            border-color: $primary !important;
        }
    }

    .jumbotron {
        background-color: $text;
    }
    .dropdown-menu {
        background-color: $text;
        .dropdown-item {
            color: $white;
            .dropdown-item-wrapper span {
                color: $white !important;
            }
            &:hover {
                background-color: $primary;
            }
            &.dropdown-button-wrapper:hover {
                background-color: transparent;
            }
        }
    }
    .form-control::placeholder {
        color: $white;
    }
    .form-group {
        .form-control:focus {
            box-shadow: none;
            border-color: $secondary !important;
        }
        label {
            color: #7f8d8e !important;
        }
        input {
            background-color: #282828;
            color: $white !important;
            & + i.icon-search {
                color: $white !important;
            }
        }
        textarea {
            background-color: #282828;
            color: $white;
        }
    }
    .datatable-row-right,
    .datatable-row-left {
        background-color: #282828;
    }
    .ngx-datatable.bootstrap .empty-row {
        color: $white;
    }
    .ngx-datatable.bootstrap .datatable-body {
        .isDeepestElement > .datatable-row-center > .datatable-body-cell{
            &:nth-child(2),
            &:nth-child(3) {
                color: $white;
            }
        }
        
        .datatable-body-row.active {
            background-color: $secondary;
            color: $black;
            &:hover {
                background-color: $secondary;
            }
            .expand-icon {
                color: $primary;
            }
        }

    }
  
    .ngx-datatable.bootstrap .datatable-body .datatable-body-row.text-muted:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    .autocomplete .highlight,
    .ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell span.highlight {
        color: $black;
    }
    .form-group .autocomplete {
        background-color: #282828 !important;
    }
    .expand-fullscreen {
        background-color: $primary;
        &:hover {
            background-color: $primary;
            color: $secondary;
        }
    }
    .json-viewer {
        background-color: $primary;
        .segment {
            .segment-main {
                .toggler:after {
                    color: $secondary;
                }
                .segment-key {
                    color: $white !important;
                }
                .segment-separator {
                    color: #999;
                }
                .segment-value {
                    color: #d2d2d2;
                }
            }
        }
    }
    .last-update {
        background-color: #282828;
    }
    .map-container text.warehouse_name {
        text-shadow:
            -1px -1px 0 #000,
            1px -1px 0 #000,
            -1px 1px 0 #000,
            1px 1px 0 #000;
    }
    .ngx-datatable.bootstrap:not(.cell-selection) .datatable-body-row:hover {
        background: #000;
    }
    .carousel {
        background-color: rgba($color: #000, $alpha: 0.25);
    }
    .long-description {
        background-color: #282828;
    }
    .full-screen-chart {
        background-color: #282828;
    }

    // ESTILOS PROPIOS
    .icon-wrapper i {
        color: #000;
    }
    .icon-wrapper:hover {
        i {
            background-color: #282828 !important;
            border-color: #282828 !important;
            color: $secondary !important;
        }
    }
    .upload-section .rounded-right {
        background-color: #282828 !important;
    }
    .col-md-6.col-12.border,
    .col-lg-6.col-12.lite-wrapper,
    .col-lg-6.col-12.svg-wrapper {
        h4 {
            color: $white;
        }
        svg text {
            fill: $white;
        }
    }
    .viewer-wrapper > svg:not(.editable),
    .map-title + svg:not(.editable) {
        background-color: #1a1a1a;
        path {
            fill: #d2d2d2;
        }
        text {
            fill: #fff;
        }
    }
    .editor-wrapper svg.editable {
        background-color: #282828;
        rect.background-wrapper {
            stroke: #000;
        }
        path.contour {
            fill: rgba(0, 0, 0, 0.4);
        }
    }
    .modal-full.modal-dialog-centered .zones-picker,
    .modal-full.modal-dialog-centered .viewBox-picker {
        background-color: #282828 !important;
        .card {
            background-color: #282828;
        }
    }
    .accordion > .card .card-header,
    .accordion > .card .collapse.show {
        background-color: $primary !important;
        input {
            background-color: #282828 !important;
        }
    }
    .accordion > .card .card-body .btn {
        background-color: $primary !important;
        &.active {
            background-color: #000 !important;
        }
        &.btn-light {
            border-color: $primary !important;
        }
        &.btn-light.btn-equipment {
            background-color: #000 !important;
        }
        color: #fff;
    }
    .btn-equipment svg {
        color: #282828 !important;
    }
    .form-control:disabled {
        border-color: #363636;
        background-color: #363636;
    }
    .form-control:disabled + i.icon-search {
        color: #000 !important;
    }
    .preloader {
        background-color: rgba(50, 50, 50, 0.8) !important;
        .loading-wrapper {
            text-shadow: 2px 2px #000 !important;
        }
    }
    .col-md-6.col-12.border,
    .col-lg-6.col-12.lite-wrapper,
    .col-lg-6.col-12.svg-wrapper {
        svg path {
            stroke: #282828;
            &.ground {
                stroke: #333;
                fill: url(#texture2) !important;
            }
            &.box {
                stroke: var(--status-boxes);
            }
        }
        svg g.selected {
            outline: dotted 7px #5292ee;
        }
    }
    #texture path {
        fill: #999 !important;
    }
    .col-lg-6.col-12.svg-wrapper.home h4 {
        color: $white;
    }
    .accordion-title {
        background-color: #1a1a1a;
        color: #fff;
    }
    .ng-select .ng-select-container {
        background-color: transparent;
    }
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        color: #fff;
        background-color: #282828;
    }
    .map-wrapper.expand {
        background-color: #282828;
    }
    .alerts-dropdown .dropdown-item,
    .warnings-dropdown .dropdown-item,
    .variables-dropdown .dropdown-item,
    .scanners-dropdown .dropdown-item,
    .reports-dropdown .dropdown-item,
    .analytics-dropdown .dropdown-item {
        color: $white !important;
    }
    .breadcrumb {
        background-color: #282828;
        .breadcrumb-item + .breadcrumb-item::before {
            background-color: $white !important;
        }
        .breadcrumb-item > a {
            border-color: #fff;
            color: #fff;
        }
        .breadcrumb-item.active > a {
            border-color: $secondary;
            background-color: $secondary;
            color: #000;
        }
        .breadcrumbs-link {
            border-right: 2px solid white;
            &:hover {
                background-color: #fff;
                color: #000;
            }
        }
        .breadcrumb-item .dropdown-item {
            background-color: #282828;
        }
        .breadcrumb-item .dropdown-item::before {
            background-color: #fff;
        }
    }
    .button-excel {
        color: $white;
    }
    .button-refresh {
        color: $white;
    }
    .button-pause {
        color: $white;
    }
    .custom-title {
        background-color: $primary;
    }
    .linesOverMap-wrapper {
        background-color: $primary;
    }
    .expand {
        background-color: #282828 !important;
    }

    .offcanvas {
        background-color: #282828 !important;
        color: #fff;
    }
    .offcanvas-header:before {
        border-bottom: 2px solid #000;
    }
    .accordion-item {
        background-color: #282828 !important;
        .accordion-button {
            background-color: #1a1a1a !important;
            color: #fff !important;
        }
    }
    table.table-compact {
        color: #fff;
    }

    .settings-box {
        box-shadow:
            rgba(255, 255, 255, 0.25) 0px 6px 12px -2px,
            rgba(255, 255, 255, 0.3) 0px 3px 7px -3px;
    }

    settings-permissions {
        h6,
        .tree-view {
            color: $white;
        }
        .card-header {
            background-color: rgb(248, 248, 248);
        }
        .fa-info-circle {
            color: var(--secondary);
            &:hover {
                color: $white;
            }
        }
    }

    .bubble,
    .bubble-without-arrow {
        box-shadow: rgba(255, 255, 255, 0.3) 1px 5px 15px -3px;
        &::after {
            border-color: #282828 transparent;
        }
    }
}
