$secondary: #31a398;
$secondaryDark: #fcde82;
$primaryHover: #cfb53b;
$link: #cfb53b;

$black: #000;
$primary: #1a1a1a;
$text: #3e3e3e;
$gray5: #555;
$gray28: #282828;
$gray61: #616161;
$gray9: #999;
$grayC: #ccc;
$grayD: #ddd;
$grayE: #eee;
$grayF5: #f5f5f5;
$white: #fff;
$active: #2da45d;
$danger: #fe840e;

:root {
  --bg-color: #{$white};
  --navbar-color: #{$white};
  --text: #{$text};
  --input-text: #{$gray61};
  --input-text-filled: #{$primary};
  --text-background: #{$white};
  --text-background-hover: #{$grayE};
  --secondary: #{$secondary};
  --secondary-text: #{$white};
  --invert-variable: invert(100%);
  --background-color:  #{$grayD};
  --disabled-text: #{$gray9};
  --datatable-header: #{$grayF5};
}
html.dark {
  --bg-color: #{$gray28};
  --navbar-color: #{$black};
  --text: #{$white};
  --input-text: #{$grayC};
  --input-text-filled: #{$white};
  --text-background: #{$text};
  --text-background-hover: #{$primary};
  --secondary: #{$secondaryDark};
  --secondary-text: #{$black};
  --invert-variable: invert(0%);
  --background-color: #{$black};
  --disabled-text: #{$gray5};
  --datatable-header: #{$text};
}

$secondary: #31a398;
$secondaryDark: #fcde82;
$primaryHover: #cfb53b;
$link: #cfb53b;

$black: #000;
$primary: #1a1a1a;
$text: #3e3e3e;
$gray5: #555;
$gray28: #282828;
$gray61: #616161;
$gray9: #999;
$grayC: #ccc;
$grayD: #ddd;
$grayE: #eee;
$grayF5: #f5f5f5;
$white: #fff;
$active: #2da45d;
$danger: #fe840e;

:root {
  --bg-color: #{$white};
  --navbar-color: #{$white};
  --text: #{$text};
  --input-text: #{$gray61};
  --input-text-filled: #{$primary};
  --text-background: #{$white};
  --text-background-hover: #{$grayE};
  --secondary: #{$secondary};
  --secondary-text: #{$white};
  --invert-variable: invert(100%);
  --background-color:  #{$grayD};
  --disabled-text: #{$gray9};
  --datatable-header: #{$grayF5};
}
html.dark {
  --bg-color: #{$gray28};
  --navbar-color: #{$black};
  --text: #{$white};
  --input-text: #{$grayC};
  --input-text-filled: #{$white};
  --text-background: #{$text};
  --text-background-hover: #{$primary};
  --secondary: #{$secondaryDark};
  --secondary-text: #{$black};
  --invert-variable: invert(0%);
  --background-color: #{$black};
  --disabled-text: #{$gray5};
  --datatable-header: #{$text};
}


.select2-container--open + label {
  color: var(--secondary) !important;
}
.select2-container--open + label + i.fas {
  transform: rotate(180deg);
  transition: 0.2s linear;
}
.select2-container--default.select2-container--disabled + label:hover,
.select2-container--default.select2-container--disabled .select2-selection--single:hover {
  cursor: not-allowed !important;
}
.select2.select2-container {
  width: 100% !important;
  & + label + i.fas {
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
    background-color: transparent;
    padding: 12px 17px;
    border: none;
    color: var(--text);
    font-weight: bold;
    height: 100%;
  }
  & + i.fas {
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
    background-color: transparent;
    padding: 12px 17px;
    border: none;
    color: var(--text);
    font-weight: bold;
    height: 100%;
  }
  .select2-selection {
    height: 40px;
    .select2-selection__arrow {
      display: none;
    }
    .select2-selection__rendered {
      line-height: 40px;
      height: 38px;
      padding-top: 8px;
      font-size: 16px;
      padding-right: 60px;
      color: var(--text);
    }
  }

  .select2-selection.select2-selection--multiple {
    background-color: transparent;
    min-height: 40px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #d2d2d2;
    border-radius: 2px;
    height: auto;
    line-height: 1;
    .select2-selection__rendered {
      height: auto;
      line-height: 13px;
      padding-top: 16px;
      .select2-selection__choice {
        font-size: 14px;
        border: none;
        border-left: 3px solid var(--text-background-hover);
        border-radius: 0;
        background-color: transparent;
        margin-right: 2px;
        margin-top: 4px;
        padding-right: 7px;
        .select2-selection__choice__remove {
          color: #ff3333;
          margin-right: 4px;
          font-size: 15px;
        }
      }
      li:first-child {
        border-left: none;
      }
    }
  }
}

.select2-container {
  .select2-dropdown {
    .select2-results__options {
      position: relative;
      .select2-results__option {
        display: flex;
        align-items: center;
        height: 40px;
        max-width: 100%;
        overflow: hidden;
        color: var(--text);
        background-color: var(--text-background);
        padding: 0 8px;
        border-bottom: 1px dotted var(--secondary);
        font-size: 12px;
        line-height: 16px;
        font-weight: bold;
        &.select2-results__option--highlighted[aria-selected] {
          background-color: var(--text-background-hover);
        }
        &.select2-results__option[aria-disabled] {
          cursor: not-allowed;
          color: var(--disabled-text);
        }
      }
      .select2-results__option[aria-selected="true"] {
          background-color: var(--secondary) !important;
          color: var(--secondary-text) !important;
          cursor: default;
      }
    }
  }
}

.select2-container--default .select2-selection--single {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #d2d2d2;
  border-radius: 2px;
  background-color: transparent;
  .select2-selection__placeholder {
    font-weight: lighter;
    font-style: italic;
  }
}
.form-group.selected {
    &:after, &:before {
        content: '';
        position: absolute;
    }
    &:before {
        margin-top: 2px;
        top: 38px;
        left: 0;
        width: 100%;
        border-bottom: 4px solid $secondary;
    }
}


.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 5px;
}
.bigdrop.select2-dropdown {
  max-height: 515px;
  .select2-results__options {
    max-height: 472px;
  }
}
.select2-container--default .select2-search--dropdown {
  background-color: var(--text-background);
  .select2-search__field{
    color: var(--text);
    background-color: var(--text-background);
    &:focus-visible {
      outline: 2px solid var(--secondary);
    }
  }
}
.select2-container--default .select2-search--inline .select2-search__field {
  font-style: italic;
}
.select2-container .select2-search--inline .select2-search__field {
  margin-top: 0;
}
// TABLAS
table.table-compact {
  width: 100%;
  font-size: 12px;
  color: #212529;
  margin-bottom: 15px !important;
  thead tr {
    th {
      border-bottom: 1px solid #d2d2d2;
      text-transform: none;
      text-align: left;
      padding: 1px 0 1px 0px;
      height: 35px;
      position: relative;
      font-weight: 600;
      font-style: italic;
      line-height: 31px;
    }
  }
  tbody tr {
      display: table-row;
      cursor: pointer;
      td {
        border-bottom: 1px solid #d2d2d2;
        padding: 1px 0 1px 0px;
        height: 29px;
      }
      tr:hover {
        background-color:#d2d2d2;
        cursor: pointer;
      }
  }
}
table.table {
    font-size: 13px;
    margin-bottom: 15px;
    thead > tr > th {
        text-transform: uppercase;
        font-weight: bold;
        background-color: #f5f5f5;
        color: $text;
        border-top: 1px solid #d2d2d2 !important;
        border-bottom: 1px solid #d2d2d2;
        padding-right: 0;
        font-size: 12px;
        span{
            font-size: 13px;
        }
        &:last-child{
            span{
                border: none;
            }
        }
    }
    tbody tr {
        border-bottom: 1px solid #d9dfe0;
        td {
            i{ padding-right: 5px; }
            .fa-circle{ color: $danger; }
            .fa-dot-circle-o{ color: $danger; }
            .fa-circle-o{ color: $danger; }
        }
        &:last-child td {
            border-bottom: 1px solid #7f7f7f;
        }
    }
}

@mixin ngx-datatable-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,.8);
  z-index: 9999;
  text-align: center;
  line-height: 35;
  font-size: 14px;
  font-weight: bold;
  text-shadow: 2px 0 0 $white, -2px 0 0 $white, 0 2px 0 $white, 0 -2px 0 $white, 1px 1px $white, -1px -1px 0 $white, 1px -1px 0 $white, -1px 1px 0 $white;
}
.ngx-datatable.bootstrap {
    box-shadow: none;
    font-size: 12px;
    &.loading:before {
        @include ngx-datatable-loading;
        content: 'Loading alerts...';
    }
    &.loading2:before {
        @include ngx-datatable-loading;
        content: "Generating report... It will be available in My Archives shortly";
        white-space: pre;
    }
    &:not(.cell-selection) .datatable-body-row:hover {
        background: var(--background-color);
    }
    .datatable-header {
        height: unset !important;
        border-top: 1px solid #d2d2d2;
        border-bottom: 1px solid #d2d2d2;
        box-shadow: 0 0 0 2px #f5f5f5;
        background-color: var(--datatable-header);
        margin-bottom: 2px;
        .datatable-header-cell {
            text-transform: uppercase;
            font-weight: bold;
            background-color: var(--datatable-header);
            color: var(--text);
            border-top: 1px solid $white;
            border-bottom: 1px solid $white;
            vertical-align: bottom;
            padding: 0.75rem;
            height: 42px;
            line-height: 1.5;
            &.sortable:hover {
                cursor: pointer;
            }
            .datatable-header-cell-label {
                line-height: 24px;
            }
            .datatable-header-cell-template-wrap {
                overflow: hidden;
                text-overflow: ellipsis;
                &:after {
                    content: '';
                    position: absolute;
                    height: 22px;
                    width: 1px;
                    background-color: #d2d2d2;
                    top: 11px;
                    right: 0;
                }
            }
            &.empty .datatable-header-cell-template-wrap:after {
            background-color: transparent;
            }
        }
        .datatable-header-cell:last-of-type {
            .datatable-header-cell-template-wrap:after {
                display: none
            }
        }
    }
    .datatable-body {
        .datatable-scroll { width: 100% !important; }
        .datatable-row-wrapper { position: relative; }
        .datatable-row-wrapper:first-of-type {
            .datatable-body-row {
                border-top: none;
            }
        }
        .datatable-row-wrapper:last-child {
            .datatable-body-row {
                border-bottom: 1px solid #7f7f7f;
            }
        }
        .datatable-body-row {
            vertical-align: top;
            border-top: 1px solid #d1d4d7;
            &.active {
                background-color: var(--text-background-hover);
                color: var(--secondary);
                &:hover {
                    background-color: var(--text-background-hover);
                }
                .expand-icon {
                    color: var(--text);
                    &:hover {color: var(--secondary)}
                }
                .highlight { color: #000; }
            }
            &.show-chart {
              background-color: #1a1a1a;
              color: $white;
            }
            &.text-muted, &.text-muted:hover {
                background-color: rgba(0, 0, 0, 0.075);
            }
            &.resolved {
                background-color: rgba(245, 245, 245, 0.3);
                color: rgba(54, 54, 54, 0.5);
            }
            .datatable-body-cell {
                overflow: hidden;
                padding: .475rem .75rem;
                text-align: left;
                vertical-align: top;
                line-height: 1.4;
                display: flex;
                align-items: center;
                span {
                    i.fa-circle {
                        font-size: 8px;
                        position: relative;
                        top: -2px;
                        padding-right: 2px;
                    }
                }
            }
        }
    }
    .datatable-footer {
        margin-top: -1px;
        .datatable-footer-inner {
            overflow: hidden;
        }
        .page-count {
            line-height: 50px;
            height: 50px;
            padding: 0 1.2rem;
        }
        .datatable-pager {
            margin: 0 10px;
            vertical-align: top;
            ul {
                li {
                    margin: 10px 0px;
                    &.pages a { padding: .5rem .9rem; }
                    &.disabled i { color: #909090; }
                    a {
                        height: 38px;
                        color: var(--text);
                        padding: .345rem .75rem;
                        font-weight: bold;
                        margin: 0;
                        border-radius: 0;
                    }
                    &:not(.disabled) {
                        &.active a {
                            background-color: transparent !important;
                            color: var(--secondary) !important;
                            &:hover {
                                cursor: default;
                            }
                        }
                        &:hover a {
                            background-color: var(--secondary);
                            color: var(--secondary-text);
                        }
                    }
                }
            }
            a {
                height: 22px;
                min-width: 24px;
                line-height: 22px;
                padding: 0;
                border-radius: 3px;
                margin: 0 3px;
                text-align: center;
                vertical-align: top;
                text-decoration: none;
                vertical-align: bottom;
                color: #ededed;
            }
            .datatable-icon-left,
            .datatable-icon-skip,
            .datatable-icon-right,
            .datatable-icon-prev {
                font-size: 18px;
                line-height: 27px;
                padding: 0 3px;
            }
        }
    }
    .datatable-icon-up::before,
    .datatable-icon-down::before {
        display: table;
        font-family: "Font Awesome 5 Free";
        font-size: 12px;
        margin-top: -3px;
        padding-left: 0.35rem;
        color: var(--secondary);
    }
    .datatable-icon-up::before { content: "\f0de"; }
    .datatable-icon-down::before { content: "\f0dd"; }
    .datatable-pager {
        i {
            font-family: "Font Awesome 5 Free";
            font-style: normal;
        }
        .datatable-icon-prev::before { content: "\f100"; }
        .datatable-icon-left::before { content: "\f104"; }
        .datatable-icon-right::before { content: "\f105"; }
        .datatable-icon-skip::before { content: "\f101"; }
    }
    .datatable-header .datatable-header-cell.resizeable {
        .resize-handle { display: none; }
    }
    .empty-row {
        padding: 20px;
        text-align: center;
        font-size: 14px;
        color: var(--text);
        border-bottom: 1px solid #7f7f7f;
    }
    .subtable {
        display: flex;
        margin-bottom: 0;
        tbody {
            width: 100%;
            background-color: #fafafa;
            tr {
                display: flex;
                &:last-child td { border-bottom: none; }
            }
        }
    }
}
.datatable-body-cell-label {
    width: 100%;
}
.expanded-cell .datatable-body-cell-label {
    width: 100%;
    .btn {
        font-size: 12px;
        i {
          font-size: 16px;
          top: 1px;
          position: relative;
        }
    }
}
.one-line {
    .datatable-body-cell {
        padding: 0 .75rem !important;
        height: 32px !important;
        line-height: 15px !important;
    }
}
.datatable-body-row.disabled {
  background-color: var(--text-background-hover);
  color: var(--disabled-text);
}
.ngx-datatable.bootstrap.alerts-table.ngx-datatable.scroll-vertical {
  .datatable-body {
    height: 100%!important;
    max-height: calc(100vh - 328px) !important;
  }
}
.ngx-datatable.bootstrap.landing-table.ngx-datatable.scroll-vertical {
  .datatable-body {
    height: 100%!important;
    max-height: calc(100vh - 236px) !important;
  }
}
.ngx-datatable.bootstrap:not(.cell-selection) .datatable-body-row.disabled:hover {
  background: var(--text-background-hover);
  cursor: not-allowed;
}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row.isSelected {
    background-color: var(--secondary);
    color: var(--secondary-text);
}
@media (max-width: 575.98px) {
    .ngx-datatable.bootstrap.alerts-table.ngx-datatable.scroll-vertical,
    .ngx-datatable.bootstrap.landing-table.ngx-datatable.scroll-vertical {
      .datatable-body {
        height: 100% !important;
        max-height: calc(100vh - 383px) !important;
      }
      .datatable-footer-inner {
          height: 0 !important;
      }
    }
}
$secondary: #31a398;
$secondaryDark: #fcde82;
$primaryHover: #cfb53b;
$link: #cfb53b;

$black: #000;
$primary: #1a1a1a;
$text: #3e3e3e;
$gray5: #555;
$gray28: #282828;
$gray61: #616161;
$gray9: #999;
$grayC: #ccc;
$grayD: #ddd;
$grayE: #eee;
$grayF5: #f5f5f5;
$white: #fff;
$active: #2da45d;
$danger: #fe840e;

:root {
  --bg-color: #{$white};
  --navbar-color: #{$white};
  --text: #{$text};
  --input-text: #{$gray61};
  --input-text-filled: #{$primary};
  --text-background: #{$white};
  --text-background-hover: #{$grayE};
  --secondary: #{$secondary};
  --secondary-text: #{$white};
  --invert-variable: invert(100%);
  --background-color:  #{$grayD};
  --disabled-text: #{$gray9};
  --datatable-header: #{$grayF5};
}
html.dark {
  --bg-color: #{$gray28};
  --navbar-color: #{$black};
  --text: #{$white};
  --input-text: #{$grayC};
  --input-text-filled: #{$white};
  --text-background: #{$text};
  --text-background-hover: #{$primary};
  --secondary: #{$secondaryDark};
  --secondary-text: #{$black};
  --invert-variable: invert(0%);
  --background-color: #{$black};
  --disabled-text: #{$gray5};
  --datatable-header: #{$text};
}


.toast {
    background-color: var(--text-background);
}
.toast-body {
    background-color: var(--text-background-hover);
}
.toast-header {
    color: var(--secondary-text);
    background-color: var(--secondary);
}
.toast-header-error {
    color: $white;
    background-color: #dc3545;
    .text-muted { color: #dedede !important; }
    .btn-close { filter: invert(100%); }
}
.toast-wrapper {
    position: fixed;
    width: 100%;
    top: 14px;
    right: 14px;
    z-index: 15;
    .toast {
        position: relative;
        float: right;
    }
}
$secondary: #31a398;
$secondaryDark: #fcde82;
$primaryHover: #cfb53b;
$link: #cfb53b;

$black: #000;
$primary: #1a1a1a;
$text: #3e3e3e;
$gray5: #555;
$gray28: #282828;
$gray61: #616161;
$gray9: #999;
$grayC: #ccc;
$grayD: #ddd;
$grayE: #eee;
$grayF5: #f5f5f5;
$white: #fff;
$active: #2da45d;
$danger: #fe840e;

:root {
  --bg-color: #{$white};
  --navbar-color: #{$white};
  --text: #{$text};
  --input-text: #{$gray61};
  --input-text-filled: #{$primary};
  --text-background: #{$white};
  --text-background-hover: #{$grayE};
  --secondary: #{$secondary};
  --secondary-text: #{$white};
  --invert-variable: invert(100%);
  --background-color:  #{$grayD};
  --disabled-text: #{$gray9};
  --datatable-header: #{$grayF5};
}
html.dark {
  --bg-color: #{$gray28};
  --navbar-color: #{$black};
  --text: #{$white};
  --input-text: #{$grayC};
  --input-text-filled: #{$white};
  --text-background: #{$text};
  --text-background-hover: #{$primary};
  --secondary: #{$secondaryDark};
  --secondary-text: #{$black};
  --invert-variable: invert(0%);
  --background-color: #{$black};
  --disabled-text: #{$gray5};
  --datatable-header: #{$text};
}


html.dark {
    hr { border-top: 1px solid #d2d2d2; }
    .card {
        background-color: $primary;
    }
    .form-group .dropdown {
        background-color: $primary;
    }

    // DATATABLES
    .ngx-datatable.bootstrap.loading:before,
    .ngx-datatable.bootstrap.loading2:before {
        background-color: rgba(0, 0, 0, .8);
        text-shadow: 2px 0 0 $black, -2px 0 0 $black, 0 2px 0 $black, 0 -2px 0 $black, 1px 1px $black, -1px -1px 0 $black, 1px -1px 0 $black, -1px 1px 0 $black;
    }
    .ngx-datatable.bootstrap .subtable tbody {
      background-color: $primary;
      color: $white;
    }
    .datatable-body-row.active + .datatable-row-detail .subtable tr.active {
        color: $primary;
        background-color: $secondary;
    }
    .datatable-body-row.active + .datatable-row-detail .subtable tr.active.text-warning,
    .datatable-body-row.active + .datatable-row-detail .subtable tr.active.text-danger {
        color: $primary !important;
    }
    .ngx-datatable.bootstrap .datatable-header {
        box-shadow: 0 0 0 2px $text;
        .datatable-header-cell {
            border-color: #202020;
        }
    }
    .datatable-body-cell-label a.fa {
        color: $white;
        &:hover{ color: $secondary; }
    }
    .ngx-datatable.bootstrap .datatable-body .datatable-body-row.text-muted:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    .ngx-datatable.bootstrap.alerts-table .datatable-body .datatable-body-row.active {
      background-color: #000;
      color: #fff;
    }
}

html, body {
  color: var(--text);
  font-family: 'Inter';
  background-color: var(--bg-color);
  scrollbar-color: var(--secondary) var(--background-color) !important;
}
body {
  overflow-x: hidden;
}
:focus {
  outline: -webkit-focus-ring-color auto 0;
}
a {
  color: var(--secondary);
  text-decoration: none;
  &:hover {
    cursor: pointer;
    color: var(--text);
    text-decoration: none;
  }
}
.highlight {
  background-color: var(--secondary);
  color: var(--secondary-text);
}
.hide-me {
  position: absolute;
  width: 100%;
  top: -9999px;
  left: -9999px;
  z-index: -1;
  opacity: 0;
}
html.no-scroll {
	position: fixed;
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.full-screen-chart {
	position: fixed;
	width: 100%;
	height: calc(100% - 66px) !important;
	left: 0;
	top: 66px;
	background-color: #fff;
	padding: 32px;
	z-index: 99;
}

// DROPDOWN
.dropdown-menu {
  min-width: 270px;
  border-radius: 1px;
  border: solid 2px #7f8d8e;
  background-color: var(--text-background);
	.dropdown-item {
    color: var(--text);
		font-size: 14px;
		padding: 0 20px;
    &.dropdown-button-wrapper:focus, &.dropdown-button-wrapper:hover {
      background-color: transparent;
    }
    &:focus, &:hover {
      background-color: var(--text-background-hover);
    }
    &.active {
      color: var(--secondary-text);
      background-color: var(--secondary);
    }
		&:last-of-type .dropdown-item-wrapper {
			border-bottom: none;
		}
		.dropdown-item-wrapper {
      height: 40px;
			border-bottom: 1px dashed #d2d2d2;
			padding: 9px 0;
      i {
        font-size: 12px;
        color: #d2d2d2;
        padding-right: 10px;
      }
      .form-check {
        padding-left: 5px;
      }
		}
	}
}

// BUTTONS
.btn-ays {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 9px 0;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 2px;
  box-shadow: none !important;
  color: $white;
  background-color: $primary;
    &:hover {
      color: var(--secondary-text);
      background-color: var(--secondary);
    }
    &:focus { box-shadow: none; }
    i {
      padding-right: 10px;
      font-size: 20px;
    }
}
.btn-group-ays {
  height: 40px;
  width: 100%;
  background-color: $primary;
  padding: 3px;
  border-radius: 2px;

  &>.btn-ays {
    height: 34px;
    width: 100%;
    padding: 0.375rem 0.75rem;

    &.active {
      color: $primary;
      background-color: $white;
      z-index: 0;

      &:hover {
        color: $primary;
        background-color: $white;
      }
    }

    &:hover {
      background-color: $primary;
      color: var(--secondary);
    }

    &:active {
      border-color: $primary;
    }
  }
}

//DROPDOWN
// .dropdown-menu .dropdown-item {
//   font-size: 16px;
//   padding: 0.25rem 2rem 0.25rem 1rem;
// }


// TABS
.nav-link {
  color: var(--text);
}
.nav-tabs{
    border-bottom: 1px solid var(--text);
    .nav-link {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }
    .nav-link.active, .nav-link.active:hover {
      color: var(--secondary-text);
      background-color: var(--secondary);
      border-color: var(--secondary);
    }
    .nav-link.active:hover { cursor: default; }
    .nav-link:hover {
      color: var(--secondary);
      background-color: var(--text-background-hover);
      border-color: var(--text-background-hover);
      border-bottom-color: var(--text);
    }
}

// NAV
.nav.nav-editor.nav-pills {
    border-right: 1px solid #d1d4d7;
    padding-right: 0;
    hr { margin: 15px; }
    .nav-link {
        font-size: 12px;
        line-height: 24px;
        border-radius: 0;
        &.active {
            background-color: $primary;
            &:hover {
                background-color: $primary;
                color: $white;
                cursor: default;
            }
        }
        &:hover {
            background-color: #f5f5f5;
            color: $secondary;
            cursor: pointer;
        }
        i {
            position: relative;
            font-size: 14px;
            padding-right: 10px;
        }
    }
}

// POPOVER
.popover {
  	max-width: 400px;
    width: 400px;
    border: 2px solid #7f8d8e;
    border-radius: 1px;
    background-color: var(--secondary);
    .popover-header {
        color: var(--secondary-text);
        background-color: var(--secondary);
    }
    .popover-body {
        color: var(--text);
        background-color: var(--text-background);
    }
    p {
        margin: 0;
        font-size: 13px;
    }
    &.bs-popover-top .popover-arrow::after { border-top-color: #7f8d8e; }
    &.bs-popover-bottom .popover-arrow::after { border-bottom-color: #7f8d8e; }
    &.bs-popover-start > .popover-arrow::after { border-left-color: #7f8d8e; }
    &.bs-popover-end > .popover-arrow::after { border-right-color: #7f8d8e; }
}

// FORMS
.form-group {
  input {
      color: var(--input-text);
      & + i.icon-search { color: var(--input-text); }
  }
  textarea {
    color: var(--input-text);
    background-color: transparent;
    &:focus {
      color: var(--text);
      background-color: transparent;
    }
  }
  .form-control:focus {
      box-shadow: none;
      border-color: var(--secondary) !important;
  }
}
.form-group.input--filled {
  input:focus {
    color: var(--input-text-filled);
  }
  input:focus + label {
    color: var(--secondary);
  }
  input:focus + label:after {
    border-bottom: 2px solid var(--secondary);
  }
}
.form-group.input--disabled {
  background-color: #eee;
  &:hover {
    cursor: not-allowed;
    & > * {
      cursor: not-allowed;
    }
  }
}
.form-group.input {
  position: relative;
	margin-bottom: 0;
	z-index: 1;
	display: inline-block;
	width: 100%;
  height: 40px;
	overflow: hidden;
	label {
		color: #6a7989;
		font-weight: bold;
		font-size: 14px;
		user-select: none;
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 0 0.25em;
		width: 100%;
		height: 28px;
		pointer-events: none;
		margin-bottom: .5rem;
		&:after, &:before {
			content: '';
			position: absolute;
			top: 2px;
			left: 0;
			width: 100%;
			height: 34px;
			border-bottom: 2px solid #b5b5b5;
		}
		&:after {
			margin-top: 0;
			border-bottom: 2px solid #B9C1CA;
			transform: translate3d(-100%, 0, 0);
			transition: transform 0.5s;
		}
		span {
			position: relative;
			display: block;
			padding: 12px 0;
			width: 100%;
		}
  }
  input {
		height: auto;
		border: none;
		margin-top: 10px;
		padding: 3px 40px 2px 4px;
		width: 100%;
		background-color: transparent;
		line-height: 26px;
		&:focus-visible {
			outline: none;
		}
		/* Chrome, Safari, Edge, Opera */
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}
  input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    padding-left: 36px;
    & + label { padding-left: 36px; }
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  .input-arrows > i.fas {
    position: absolute;
    left: 10px;
    font-size: 22px;
    &.fa-angle-up { top: 0; }
    &.fa-angle-down { top: 16px; }
    &:hover { cursor: pointer; }
  }
}
.readonly--label {
	color: #6a7989;
	font-weight: bold;
	font-size: 12px;
}

// AMIMATIONS
@-webkit-keyframes icon-beat {
    0% {
        -webkit-transform: scale(1.5);
        -ms-transform: scale(1.5);
        transform: scale(1.5);
    }
    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes icon-beat {
    0% {
        -webkit-transform: scale(2);
        -ms-transform: scale(2);
        transform: scale(2);
    }
    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}
